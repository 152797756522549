import React from "react"
import { PageProps } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import EmailConfirmation from "../components/auth/EmailConfirmation"

type DataProps = {}

const EmailConfirmationPage: React.FC<PageProps<DataProps>> = ({}) => {
  return (
    <Layout hasHero={false}  showStickFooter={false} showFooter={false}>
      <SEO title="Confirm your Email" />
      <EmailConfirmation />
    </Layout>
  )
}

export default EmailConfirmationPage
