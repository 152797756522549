import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { FormContextProvider } from "../../contexts/formContext"
import { FormInputState } from "../../utils/validations"
import EmailConfirmationForm, {
  EmailConfirmationProps,
} from "./EmailConfirmationForm"

const intialFormInput: Record<string, FormInputState> = {
  confirmEmailToken: {
    value: "",
    label: "Confirmation Code",
    validation: [{ type: "required" }, { type: "min", value: 6 }],
  },
}

const EmailConfirmation = () => {
  const props: EmailConfirmationProps = {
    title: "Thanks for signing up!",
    buttonText: "Confirm Code",
    instructionParagraph:
      "We have sent you an email containing a confirmation code. Please enter the code below to confirm your email address for your security.",
    resendTokenText: "Resend code",
  }

  const [cleanUp, setCleanUp] = useState(false)

  useEffect(() => {
    setCleanUp(false)
    return () => {
      setCleanUp(true)
    }
  }, [])
  return (
    <>
      {!cleanUp && (
        <FormContextProvider initialInputs={intialFormInput}>
          <EmailConfirmationForm {...props} />
        </FormContextProvider>
      )}
    </>
  )
}

export default EmailConfirmation
