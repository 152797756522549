import React, { useState, useEffect, ChangeEvent } from "react"
import { navigate } from "gatsby"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import styled from "styled-components"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useApiMutation } from "../../hooks/useApiMutation"
import { useLazyApi } from "../../hooks/useLazyApi"
import { useAuthState } from "../../contexts/authContext"
import { Button } from "../ui/Button"
import { FormInput } from "../ui/Input"
import { Avatar, Container, FormControl, Typography } from "@material-ui/core"
import { FormContextType, useForm } from "../../contexts/formContext"
import { getLs } from "../../utils/localStorage"

const SUCCESS_URL = "email-confirmed"

export interface EmailConfirmationProps {
  title: string
  buttonText: string
  instructionParagraph: string
  resendTokenText: string
  redirectPage?: {
    slug: string
  }
  redirectLandingPage?: {
    slug: string
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "var(--color-teal)",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    marginTop: "1rem",
  },
}))

const CenteredContainer = styled.div`
  text-align: center;
  margin-top: 1rem;
`

const EmailConfirmationForm = (props: EmailConfirmationProps) => {
  const classes = useStyles()
  const [confirmMessage, setConfirmMessage] = useState("")
  const { formState, formActions } = useForm() as FormContextType
  const authState = useAuthState()
  const { title, buttonText, instructionParagraph, resendTokenText } = props
  const [queryParamEmail, setQueryParamEmail] = useState("")
  const [queryParamToken, setQueryParamToken] = useState("")

  const [confirmEmailToken, { loading, error, data, errMsg }] = useApiMutation(
    "account",
    "confirmEmailToken"
  )
  const [
    resendEmailConfirmationToken,
    { loading: sendEmailLoading, error: sendEmailError, data: sendEmailData },
  ] = useLazyApi("account", "resendEmailConfirmationToken")

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!formActions.isValid()) {
      return
    }

    let newsletterEmail = getLs("newsletter_email")
    if (newsletterEmail) {
      newsletterEmail = newsletterEmail.value
    }

    confirmEmailToken({
      variables: {
        input: {
          email: queryParamEmail || authState.email,
          token: formState.inputs.confirmEmailToken.value,
        },
      },
    })
  }

  const handleResendToken = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    setConfirmMessage("")

    let newsletterEmail = getLs("newsletter_email")
    if (newsletterEmail) {
      newsletterEmail = newsletterEmail.value
    }

    resendEmailConfirmationToken({
      variables: {
        input: {
          email: authState.email || queryParamEmail || newsletterEmail,
        },
      },
    })
  }

  useEffect(() => {
    if (window) {
      const urlParams = new URLSearchParams(window.location.search)
      const emailEncoded = urlParams.get("email")
      const tokenEncoded = urlParams.get("token")
      let email = ""
      let token = ""

      if (emailEncoded) {
        email = decodeURIComponent(emailEncoded)
        setQueryParamEmail(email)
      }
      if (tokenEncoded) {
        token = decodeURIComponent(tokenEncoded)
        setQueryParamToken(token)
      }
    }
  }, [])

  useEffect(() => {
    if (data && data.confirmEmailToken) {
      const redirectPage =
        props.redirectPage || props.redirectLandingPage
          ? props.redirectPage?.slug || props.redirectLandingPage?.slug
          : SUCCESS_URL
      navigate(`/${redirectPage}`)
    }
  }, [data])

  useEffect(() => {
    if (error) {
      setConfirmMessage(error.message)
    }
  }, [error])

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <>
          <Avatar className={classes.avatar}>
            <MailOutlineIcon />
          </Avatar>
          <Typography
            component="h1"
            variant="h5"
            data-test-id={"form-title"}
            align="center"
          >
            {title || "Confirm Your Email!"}
          </Typography>
          <CenteredContainer>
            <p>{instructionParagraph}</p>
          </CenteredContainer>
        </>

        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormInput
                  fieldId="confirm-email"
                  fieldName="confirmEmailToken"
                  inputs={formState.inputs}
                  inputHandler={formActions.inputHandler}
                />
              </FormControl>
            </Grid>
          </Grid>
          {confirmMessage && <p className="error">{confirmMessage}</p>}

          <Grid container justify="center" className={classes.submit}>
            <Button
              type="primary"
              submit={true}
              id="email_confirmation"
              sectionId="email_confirmation"
              title={buttonText}
              disabled={data || loading}
            />
          </Grid>

          <CenteredContainer>
            <a href="#!" onClick={handleResendToken}>
              {resendTokenText}
            </a>
          </CenteredContainer>

          {sendEmailError && (
            <p className="error">
              {errMsg ||
                "We could not resend the confirmation email at this time"}
            </p>
          )}
          {sendEmailData && (
            <p>{errMsg || "Confirmation sent. Please check your email."}</p>
          )}

          {(loading || sendEmailLoading) && (
            <CenteredContainer>
              <CircularProgress />
            </CenteredContainer>
          )}
        </form>
      </div>
    </Container>
  )
}

export default EmailConfirmationForm
